import qs from 'qs'

export const getParams = () => {
  const params = qs.parse(window.location.search.slice(1))
  return params
}

export const getAppState = () => {
  const params = getParams()
  return params.appstate
}

export const getAuth0State = () => {
  const params = qs.parse(window.location.hash.slice(1))
  return params.state
}
