import { Button as AntdButton } from 'antd'
import { FONT_SIZES } from './const'
import styled from '@emotion/styled'

export const Button = styled(AntdButton)`
  margin-bottom: 13px;
  flex-shrink: 0;
  border-radius: 3px;

  line-height: ${props => {
    switch (props.size) {
      case 'large':
        return '60px'
      case 'small':
        return '30px'
      default:
        return '40px'
    }
  }};
  width: ${props => props.width || '100%'};
  box-sizing: border-box;
  font-size: ${props => {
    switch (props.size) {
      case 'large':
        return FONT_SIZES[500]
      case 'small':
        return FONT_SIZES[300]
      default:
        return FONT_SIZES[400]
    }
  }};
  font-family: 'Couture';
  letter-spacing: 2.1px;
  text-transform: ${props => (props.noTextTransform ? 'none' : 'uppercase')};
  &,
  &:hover,
  &:active,
  &:focus,
  &:disabled {
    color: ${props => {
      if (props.primary === 'true') return '#021473'
      return 'white'
    }};
    background: ${props => {
      if (props.primary === 'true') return 'white'
      if (props.danger === 'true') return '#e83d4a'
      if (props.dark === 'true') return '#000'

      return 'none'
    }};
    border: solid 1px rgba(255, 255, 255, 0.4);
    border-color: ${props => {
      if (props.primary === 'true') return 'white'
      if (props.danger === 'true') return '#e83d4a'
    }};
  }
  &:disabled {
    opacity: 0.5;
  }
`
