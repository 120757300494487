import React from 'react'
import styled from '@emotion/styled'
import { css } from 'emotion'

const Container = styled.div`
  flex: 1.5 1.5 auto;
  display: flex;
  flex-direction: column;
`
const Title = styled.h1`
  font-weight: 700;
  margin-bottom: 3rem;
  font-size: 1.75rem;
  line-height: 1.5em;
  font-family: 'Couture';
  text-align: center;
  flex: 0 0 auto;
  color: white;
`
const Detail = styled.p`
  text-align: center;
  flex: 1 1 auto;
  font-family: Quicksand;
`
const ThanksPage = ({ email }) => {
  return (
    <>
      <Container>
        <Title>THANK YOU</Title>
        <Detail>
          <p>
            for joining the wãvescool waving list whether now or later, we
            would love to have you in the wãvescool eco-system
          </p>
          <p className={css`font-size: 1.075rem;`}>be on the lookout for an email as soon as the tides collide</p>
        </Detail>
      </Container>
    </>
  )
}
export default ThanksPage
