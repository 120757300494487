import { Component } from 'react'
import qs from 'qs'
import { getCallbackUrl, getParams } from './utils'
import { getWebAuth } from './webAuth'
import { getStateData } from './State'
import { getAuth0State } from '../utils'

class Auth0 extends Component {
  handleRedirect = ({ token, appState, err }) => {
    const params = getParams()
    const { provider } = params
    const callbackUrl = getCallbackUrl()
    const existingQuery = qs.parse(
      callbackUrl.split('?').length > 1 ? callbackUrl.split('?')[1] : '',
    )
    const error = err ? true : null
    const queryString = qs.stringify({
      token,
      provider,
      ...appState,
      ...existingQuery,
      error,
    })
    const redirectUrl = `${callbackUrl.split('?')[0]}?${queryString}`
    window.location.href = redirectUrl
  }
  componentDidMount() {
    const webAuth = getWebAuth()
    webAuth.parseHash({ hash: window.location.hash }, (err, authResult) => {
      if (err) {
        const nounce = getAuth0State()
        const appState = getStateData(nounce)
        this.handleRedirect({ appState, err })
      }
      const token = authResult.accessToken
      const nounce = authResult.state
      const appState = getStateData(nounce)

      this.handleRedirect({ token, appState })
    })
  }
  render() {
    return null
  }
}

export default Auth0
