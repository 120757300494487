import { Component } from 'react'
import { getWebAuth } from './webAuth'
import { createState } from './State'

const createRedirectUri = provider => {
  const callbackUrl = '/?appstate=connectsocial'
  const origin = window.location.origin
  const redirectUri = `${origin}/login/callback?callback=${encodeURIComponent(
    callbackUrl,
  )}&provider=${provider}`
  return redirectUri
}

class Auth0 extends Component {
  componentDidMount() {
    console.log(window.location.href)
    console.log(this.props.location.state)
    const appState = this.props.location.state
    const { nounce } = createState(appState)
    const { provider } = this.props.match.params
    const redirectUri = createRedirectUri(provider)

    const webAuth = getWebAuth()

    if (provider === 'twitter') {
      webAuth.authorize({
        connection: 'twitter',
        redirectUri,
        state: nounce,
      })
    }

    if (provider === 'facebook') {
      webAuth.authorize({
        connection: 'facebook',
        redirectUri,
        state: nounce,
        connection_scope:
          'manage_pages,read_insights,instagram_manage_insights',
      })
    }

    if (provider === 'google') {
      webAuth.authorize({
        connection: 'google-oauth2',
        responseType: 'token',
        accessType: 'offline',
        approvalPrompt: 'force',
        redirectUri,
        state: nounce,
        connection_scope:
          'https://www.googleapis.com/auth/youtube.readonly,https://www.googleapis.com/auth/yt-analytics.readonly',
      })
    }

    if (provider === 'instagram') {
      webAuth.authorize({
        connection: 'instagram',
        responseType: 'token',
        state: nounce,
        redirectUri,
      })
    }
  }
  render() {
    return null
  }
}

export default Auth0
