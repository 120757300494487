function randomString(length) {
  const charset =
    '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-._~'
  let result = ''

  while (length > 0) {
    const bytes = new Uint8Array(16)
    const random = window.crypto.getRandomValues(bytes)

    // eslint-disable-next-line no-loop-func
    random.forEach(c => {
      if (length === 0) {
        return
      }
      if (c < charset.length) {
        result += charset[c]
        length--
      }
    })
  }
  return result
}

const createNounce = () => randomString(16)

export const createState = (data) => {
    const nounce = createNounce()
    sessionStorage.setItem(nounce, JSON.stringify(data))
    return { nounce, data }
}

export const getStateData = (nounce) => {
    const data = JSON.parse(sessionStorage.getItem(nounce))
    return data
}