import qs from 'qs'

export const getParams = () => {
  const params = qs.parse(window.location.search.slice(1))
  return params
}

export const getCallbackUrl = () => {
  const params = getParams()
  return params.callback
}
