import React, { useState } from 'react'
import uikit from './uikit'
import styled from '@emotion/styled'
import { Icon } from 'antd'
import { Redirect } from 'react-router-dom'

const Container = styled.div`
  flex: 2 2 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
const Title = styled.h1`
  font-weight: 700;
  font-size: 1.75rem;
  line-height: 1.5em;
  font-family: 'Couture';
  text-align: center;
  color: white;
`
const SocialButton = styled(uikit.Button)`
  display: flex;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  > i {
    vertical-align: middle;
    font-size: 2rem;
  }
`
const SocialButtonContainer = styled.div`
  display: grid;
  grid-template-columns: 120px 120px;
  grid-template-rows: 120px 120px;
  justify-items: center;
  align-items: center;
  margin: 0 auto 3em auto;
`
const ConnectSocialPage = ({ onClick, email, name, socials }) => {
  const [redirect, setRedirect] = useState(false)
  const handleSocialLogin = provider => setRedirect(provider)
  if (redirect)
    return (
      <Redirect
        to={{
          pathname: `/login/${redirect}`,
          state: { email, name, socials },
        }}
      />
    )

  return (
    <>
      <Container>
        <Title>CONNECT YOUR SOCIAL</Title>
        <SocialButtonContainer>
          <SocialButton
            onClick={() => handleSocialLogin('instagram')}
            primary={'instagram' in socials ? 'true' : 'false'}
          >
            <Icon type="instagram" />
          </SocialButton>
          <SocialButton
            onClick={() => handleSocialLogin('facebook')}
            primary={'facebook' in socials ? 'true' : 'false'}
          >
            <Icon type="facebook" theme="filled" />
          </SocialButton>
          <SocialButton
            onClick={() => handleSocialLogin('google')}
            primary={'google' in socials ? 'true' : 'false'}
          >
            <Icon type="youtube" theme="filled" />
          </SocialButton>
          <SocialButton
            onClick={() => handleSocialLogin('twitter')}
            primary={'twitter' in socials ? 'true' : 'false'}
          >
            <Icon type="twitter" />
          </SocialButton>
        </SocialButtonContainer>
        <uikit.Button onClick={onClick} primary="true">
          DONE
        </uikit.Button>
      </Container>
    </>
  )
}

export default ConnectSocialPage
