import gql from 'graphql-tag'
import { unauthClient } from './apollo-client'

const UPSERT_PENDING_USER = gql`
  mutation upsertPendingUser($email: String!, $name:  String!, $socials: [String!]) {
    upsertPendingUser(
      email: $email
      name: $name
      socials: $socials
    )
  }
`

export const upsertPendingUser = async ({ email, name, socials }) => {
  const { errors } = await unauthClient.mutate({
    mutation: UPSERT_PENDING_USER,
    variables: {
      email,
      name,
      socials,
    },
  })
  if (errors) {
    console.log("Can't upsert pending user")
    return false
  } else {
    console.log('Upsert pending user successful!')
    return true
  }
}
