import axios from 'axios'
import { getBackendUrl } from './backend-url'

let _appConfig = null

export const fetchConfig = async () => {
  const baseURL = getBackendUrl()
  const response = await axios.post(`${baseURL}/appconfig.get`, {})
  const config = response.data
  setAppConfig(config)
}

export const getAppConfig = () => _appConfig

export const setAppConfig = config => {
  _appConfig = config
}
