import React from 'react'
import ReactDOM from 'react-dom'
import 'antd/lib/message/style/css'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Auth0 from './auth/Auth0'
import LoginCallback from './auth/LoginCallback'
import { SettingPage } from './config/SettingPage'
import { Starter } from './Starter'

ReactDOM.render(
  <Starter>
    <Router>
      <div>
        <Route exact path="/" component={App} />
        <Route exact path="/setting" component={SettingPage} />
        <Route
          exact
          path="/login/callback"
          component={props => <LoginCallback {...props} />}
        />
        <Route exact path="/login/:provider" component={Auth0} />
      </div>
    </Router>
  </Starter>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
