// TODO: Refactor => remove antd, emotion => can reuse every repo
import React, { Component } from 'react'
import { getBackendUrl } from './backend-url'

export class SettingPage extends Component {
  state = {
    backendUrlValue: getBackendUrl(),
  }

  handleChange = e => {
    this.setState({ backendUrlValue: e.target.value })
  }
  onSave = e => {
    e.preventDefault()
    const { backendUrlValue, customDomain } = this.state
    const backendUrl =
      backendUrlValue === 'custom' ? customDomain : backendUrlValue
    localStorage.setItem('BACKEND_URL', backendUrl)
    alert('success')
  }
  render() {
    const { backendUrlValue } = this.state
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        {backendUrlValue === 'custom' ? (
          <input
            placeholder="http://YOUR_DOMAIN.com"
            onChange={e => this.setState({ customDomain: e.target.value })}
            style={{
              width: '250px',
            }}
          />
        ) : (
          <div>{backendUrlValue}</div>
        )}
        <div
          style={{
            marginTop: '16px',
          }}
        >
          <select
            value={this.state.backendUrlValue}
            onChange={this.handleChange}
            style={{
              width: '250px',
            }}
          >
            <option value="https://api.wavescool.com">production</option>
            <option value="https://wavescool.phantompage.com">staging</option>
            <option value="http://localhost:4000">local</option>
            <option value="custom">custom</option>
          </select>
        </div>
        <div
          style={{
            marginTop: '16px',
          }}
        >
          <button
            onClick={this.onSave}
            style={{
              width: '250px',
            }}
          >
            Save
          </button>
        </div>
      </div>
    )
  }
}

export default SettingPage
