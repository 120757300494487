import auth0 from 'auth0-js'
import { getAppConfig } from '../config/app-config.js'

let _webAuth = null

export const getWebAuth = () => {
  if (!_webAuth) {
    _webAuth = new auth0.WebAuth(getAppConfig().auth0)
  }
  return _webAuth
}
