import styled from '@emotion/styled'

const HIDDEN_POSITION = {
  TOP: 'top',
  BOTTOM: 'bottom',
}

const State = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 100%;
  max-width: 375px;
  height: 100%;
  max-height: calc(100% - 70px);
  overflow: auto;
  display: flex;
  flex-direction: column;

  align-items: center;
  margin: 0 auto;
  padding: 40px 50px 40px;
  color: white;
  transition: 0.5s cubic-bezier(0, 0, 0.3, 1);
  transform: ${props => {
    if (!props.visible && props.hiddenPosition === HIDDEN_POSITION.TOP) {
      return `translate(-50%, -100px)`
    }
    if (!props.visible && props.hiddenPosition === HIDDEN_POSITION.BOTTOM) {
      return `translate(-50%, 100px)`
    }
    return 'translate(-50%, 0)'
  }};
  opacity: ${props => (props.visible ? 1 : 0)};
  pointer-events: ${props => (props.visible ? 'auto' : 'none')};
  z-index: 1;
  &:before {
    content: '';
    flex: 1 1 auto;
    display: block;
  }
`

State.HIDDEN_POSITION = HIDDEN_POSITION

export default State
