export const FONT_SIZES = {
  100: '0.41rem',
  200: '0.512rem',
  300: '0.64rem',
  400: '0.8rem',
  500: '1rem',
  600: '1.25rem',
  700: '1.563rem',
  800: '1.953rem',
  900: '2.441rem',
  1000: '3.052rem',
  1100: '3.815rem',
  1200: '4.758rem',
}
