import { useEffect, useState } from 'react'

export const useStarter = (asyncOperations = []) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [result, setResult] = useState(null)

  useEffect(() => {
    Promise.all(asyncOperations.map(op => op()))
      .then(result => {
        setResult(result)
        setLoading(false)
      })
      .catch(e => {
        setLoading(false)
        setError(e)
      })
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { loading, error, result }
}

export default useStarter
