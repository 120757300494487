import React, { useState, useMemo } from 'react'

import { css } from 'emotion'

import JoinPage from './JoinPage'
import State from './State'
import ThanksPage from './ThanksPage'
import backButtonImage from './images/back.png'
import backgroundImage from './images/bg.png'
import './fonts/fonts.css'
import ConnectSocialPage from './ConnectSocialPage'
import { upsertPendingUser } from './gql'
import { getAppState } from './utils'
import { getParams } from './auth/utils'
import message from './Message'

export const BackButton = props => (
  <div
    className={css`
      position: relative;
      display: flex;
      align-items: center;
      padding: 20px;
      font-size: 18px;
      font-weight: bold;
      color: white;
      transition: opacity 0.2s;
      opacity: ${props.opacity};
    `}
    onClick={props.onClick}
  >
    <img
      alt="back button"
      src={backButtonImage}
      height={28}
      style={{ opacity: 0.75 }}
    />
    <span style={{ marginLeft: 20 }}>{props.children}</span>
  </div>
)

const STATE = {
  JOIN: 'join',
  CONNECT_SOCIAL: 'connectsocial',
  THANKYOU: 'thankyou',
}

const InvitationPageContent = ({ updatePageState, state, params }) => {
  const [email, setEmail] = useState(params.email || '')
  const [name, setName] = useState(params.name || '')
  const socials = params.socials || {}
  return (
    <>
      <State visible={state === STATE.JOIN}>
        <JoinPage
          onClick={({ email, name }) => {
            updatePageState(STATE.CONNECT_SOCIAL)
            setEmail(email)
            setName(name)
          }}
        />
      </State>
      <State visible={state === STATE.CONNECT_SOCIAL}>
        <ConnectSocialPage
          socials={socials}
          name={name}
          email={email}
          onClick={async () => {
            const _socials = Object.values(socials)
            const ok = await upsertPendingUser({
              email,
              name,
              socials: _socials,
            })
            if (ok) {
              updatePageState(STATE.THANKYOU)
            } else {
              message.error("Can't upsert.")
            }
          }}
        />
      </State>
      <State visible={state === STATE.THANKYOU}>
        <ThanksPage email={email} />
      </State>
    </>
  )
}

const InvitationPage = () => {
  const params = getParams()
  let { token, provider, name, email, socials, error } = params
  if (token) {
    socials = Object.assign(socials || {}, { [provider]: token })
  }
  if (error) {
    message.error('Could not connect social.')
  }
  const [state, setState] = useState(getAppState() || STATE.JOIN)

  const updatePageState = state => {
    setState(state)
  }

  const content = useMemo(
    () => (
      <InvitationPageContent
        state={state}
        updatePageState={updatePageState}
        params={{ name, email, socials }}
      />
    ),
    [state, name, email, socials],
  )

  return (
    <div
      className={css`
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;
        padding: env(safe-area-inset-top) env(safe-area-inset-right)
          env(safe-area-inset-bottom) env(safe-area-inset-left);
      `}
    >
      <img
        alt="background"
        src={backgroundImage}
        className={css`
          position: absolute;
          width: 100%;
          height: auto;
          z-index: -1;
          bottom: 0;
          overflow: hidden;
        `}
      />
      {content}
    </div>
  )
}

export default InvitationPage
