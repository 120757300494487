import { message } from 'antd'
import { useStarter } from './config/app-starter'
import { fetchConfig } from './config/app-config'
import { useEffect } from 'react'

export const Starter = ({ children }) => {
  const asyncOperations = [fetchConfig]
  const { loading, error } = useStarter(asyncOperations)
  useEffect(() => {
    if (!loading && error) {
      message.error(`can't load configuration (${error.message})`)
    }
    return () => {}
  }, [loading, error])
  if (loading) {
    return null
  }
  if (error) {
    return children
  }
  return children
}
