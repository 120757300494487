export const getBackendUrl = () => {
  if (!window.BASE_CONFIG) {
    window.BASE_CONFIG = {}
  }
  return (
    localStorage.getItem('BACKEND_URL') ||
    window.BASE_CONFIG.BACKEND_URL ||
    'https://api.wavescool.com'
  )
}

export const setBackendUrl = backendUrl => {
  localStorage.setItem('BACKEND_URL', backendUrl)
}

export default getBackendUrl
