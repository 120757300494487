import { css } from 'emotion'
import message from './Message'
import React, { useState } from 'react'
import styled from '@emotion/styled'
import uikit from './uikit'

function validateEmail(email) {
  var re = /\S+@\S+\.\S+/
  return re.test(email)
}

const Title = styled.h1`
  color: white;
  font-weight: 700;
  margin-bottom: 4rem;
  font-size: 1.75rem;
  line-height: 1.5em;
  font-family: 'Couture';
`

const JoinWaitingListButton = ({ name, email, onClick }) => {
  const [loading, setLoading] = useState(false)
  return (
    <uikit.Button
      size="large"
      loading={loading}
      className={css`
        margin-top: 2rem;
      `}
      onClick={async () => {
        if (loading) {
          return
        }
        if (name === '' || email === '') {
          let fields = []
          if (name === '') {
            fields.push('name')
          }
          if (email === '') {
            fields.push('email')
          }
          message.error(`[${fields.join(', ')}] are required`)
          return
        }
        if (!validateEmail(email)) {
          message.error('Please input a valid email.')
          return
        }
        try {
          setLoading(true)
          onClick && (await onClick({name, email}))
          setLoading(false)
        } catch (err) {
          console.error(err)
          setLoading(false)
          message.error(err.message)
        }
      }}
      // disabled
    >
      SUBMIT
    </uikit.Button>
  )
}
const JoinPage = ({ onClick }) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  return (
    <>
      <Title>JOIN WÃVECOOL WAVING LIST</Title>
      <form
        className={css`
          flex: 0 0 auto;
          width: 100%;
          height: auto;
          font-size: 12px;
          font-weight: bold;
        `}
      >
        <uikit.Input
          id="name"
          label="Name"
          type="text"
          value={name}
          onChange={e => setName(e.target.value)}
        />
        <uikit.Input
          id="email"
          label="E-mail"
          type="email"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <JoinWaitingListButton name={name} email={email} onClick={onClick} />
      </form>
    </>
  )
}

export default JoinPage
